import Dashboard from './components/Dashboard';
import Modal from './components/modal'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isSmall } from './utility'
import { getLink } from './utility';
import E404 from './components/404'
import layout from "./dataModel/layout";
import "./css/main.css";

const allLinks = layout.smallTiles.map(({ link }) => getLink(link));
const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={[...allLinks, '/']}>
                    {isSmall && <Modal />}
                    <Dashboard />
                </Route>
                <Route component={E404} />
            </Switch>
        </Router>)
}

export default App