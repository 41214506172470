
const skillsWordCloud = ['C++', 'Python', 'Haskell', 'Shell Scripting', 'Java', 'Multi-threaded', 'Low-latency',
    'Frontend', 'OOP', 'Agile', 'Data pipelines', 'Data lakes', 'Azure Data Factory', 'Large-scale', 'Analysis',
    'Visualization', 'High-performance', 'Distributed', 'Performance analysis', 'Microservices', 'Cloud architecture',
    'Azure', 'GCP', 'Git', 'LeetCode', 'CodeForces', 'Codechef', 'Docker/Kubernetes', 'CI/CD', 'Debugging', 'Profiling',
    'SQL', 'NoSQL', 'Unit testing', 'Integration testing', 'Performance testing', 'Analytical', 'Critical thinking',
    'Algorithmic', 'Teamwork', 'Collaboration', 'Presentation', 'Mentorship', 'Project management',
    'Continuous learning', 'Adaptability', 'Quick learner', 'Strong work ethic', 'Dedication', 'Results-oriented',
    'Prioritization', 'Organization', 'Meeting deadlines']

export default skillsWordCloud