import { AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { MdLaptop } from 'react-icons/md'
import { IoLanguage, IoLocationSharp } from 'react-icons/io5'
import { FiSend } from 'react-icons/fi'
import { links } from '../dataModel/miscellaneous'
import { addBold } from '../utility'
import { getLink } from '../utility'
const dp = './static/resources/shubham-display.png'

const me = {
    img: {
        src: dp,
        id: 'me-im'
    },
    head: {
        className: "span side-by-side",
        title: "Hey!! I'm Shubham Awasthi!",
        id: 'me-ab',
        para: [
            "Experienced Software Engineer with a focus on designing and developing high-performance, low-latency systems.",
            "Passionate about solving challenging problems and improving system performance.",
        ].map(addBold)
    },
    sections: [

        {
            className: " box", Icon: IoLocationSharp,
            title: "Location",
            id: 'me-lo',
            para: [
                "Lucknow, Uttar Pradesh, India",
            ]
        },
        {
            className: " box", Icon: IoLanguage,
            title: "Language",
            id: 'me-la',
            para: [
                "English, Hindi",
            ]
        },
        {
            className: "link box", Icon: MdLaptop, link: getLink(`projects`),
            title: "Projects",
            id: 'me-pr',
            para: [
                "I have done quite a few projects mostly based on *web* and *cloud*. You can find some of them in the Projects section.",
            ].map(addBold)
        },
        {
            className: "link box", Icon: AiOutlineFundProjectionScreen, link: getLink(`experience`),
            title: "Work Experience",
            id: 'me-we',
            para: [
                "I possess approximately four years of professional experience in designing and developing high-performance infrastructure projects. Please refer to the 'Work Experience' section for further details.",
            ]
        },
        {
            className: "link span box", Icon: FiSend, link: getLink(`contact`),
            id: 'me-co',
            title: "Contact",
            para: [
                addBold("If you have *work oportunity* for me or a *project idea* to *collaborate*, I would love to discuss."),
                <span>Please connect with me at: <a className="link" href={links.mailLink}>{links.mail}</a> or use the contact section.</span>,
            ]
        },
    ]
}

export default me;