import React from 'react'

const jpImg = './static/resources/experience/JPMorgan.jpeg'
const mcnImg = './static/resources/experience/MCN.jpeg'
const bcmImg = './static/resources/experience/bechangemaker.png'
const acmImg = './static/resources/experience/acmvit.png'
const gscoImg = './static/resources/openSource/gsoc.png'
const microsfotImg = './static/resources/experience/microsoft.png'
const googleImg = './static/resources/experience/google.png'

const experiences = [
    {
        companyName: "Google",
        role: "Software Engineer III",
        startDate: "07/2023",
        endDate: "Present",
        info: [
            "Member of Search Serving Infra, responsible for improving system efficiency of Google Search.",
            "Sesigned & developed high-performance infrastructure components of Query Result System for Google Search, serving over 500k queries per second.",
            "Experience with multi-threaded C++ development for low-latency applications.",
            "Designed & developed frameworks and tools for system latency analysis, including trace collection, critical path identification, and performance bottleneck analysis.",
            "Key Exposure: High-Volume Low-Latency Systems, C++ Proficiency, System Performance Analysis",
        ],
        links: [
            { title: "About Employer", link: "https://about.google/" },
        ],
        icon: <img src={googleImg} width="100%" alt="gsoc" />
    }, {
        companyName: "Microsoft",
        role: "Software Engineer",
        startDate: "07/2021",
        endDate: "07/2022",
        info: [
            "Member of the Azure Commerce Data Platform, responsible for handling the commerce data.",
            "Developed a high-throughput data pipeline framework for processing and transforming large volumes of data within Azure data lakes.",
            "Designed & implemented a platform for automated data subscriber onboarding with integrated data pipeline validation, resulting in a significant reduction in manual effort.",
            "Key Exposure: Large-Scale Data Processing, Distributed Systems, Data Lakes, Azure Data Factory",
        ],
        links: [
            { title: "About Employer", link: "https://www.microsoft.com/" },
        ],
        icon: <img src={microsfotImg} style={{paddingTop: 5}} width="80%" alt="gsoc" />
    }, {
        companyName: "Google Summer of Code with Haskell.org",
        role: "Student Developer",
        startDate: "06/2020",
        endDate: "09/2020",
        info: [
            "Improve existing Haskell-Package-Builder.",
            "Update the flow & extract more information out of a Haskell Package."
        ],
        links: [
            { title: "About Program", link: "https://summerofcode.withgoogle.com/" },
            { title: "Certificate", link: "https://drive.google.com/file/d/1wcUBa9wQKsIaPnmbmitflnz-NTSIzK7q/view?usp=sharing" }
        ],
        icon: <img src={gscoImg} width="100%" alt="gsoc" />
    },
    {
        companyName: "JPMorgan Chase and Co.",
        role: "Software Engeener Intern",
        startDate: "06/2020",
        endDate: "07/2020",
        info: [
            "Worked in a team of 6 and experienced agile SDLC.",
            "Built a system using team collaboration tools like Jira board and git under some experienced mentors"
        ],
        links: [
            { title: "About Employer", link: "https://www.jpmorganchase.com/" },
            { title: "Certificate", link: "https://drive.google.com/file/d/11qNT21zCnGZnV01PExmEECM-xThEmYDy/view" }
        ],
        icon: <img src={jpImg} width="100%" alt="JP-Morgan" />
    },
    {
        companyName: " MCN Solutions Pvt. Ltd",
        role: "Summer Intern",
        startDate: "06/2019",
        endDate: "07/2019",
        info: [
            "Involved in partial fulfilment of an OAuth based authentication system using NodeJs"
        ],
        links: [
            { title: "About Employer", link: "https://www.mcnsolutions.net/" },
            { title: "Certificate", link: "https://drive.google.com/file/d/1_vbCGgM4CcjCKVfw4s-7-cGhmiOe0z5k/view" }
        ],
        icon: <img src={mcnImg} width="100%" alt="MCN-solutions" />
    },
    {
        companyName: " BeChangeMaker 2018",
        role: "Participant",
        startDate: "04/2018",
        endDate: "10/2018",
        info: [
            "A social entrepreneurial training program by WorldSkills and the HP Foundation.",
            "Completed all training offered through BeChangeMaker.",
        ],
        links: [
            { title: "About Program", link: "https://worldskills.org/what/projects/bechangemaker/2018/prog/" },
            { title: "Certificate", link: "https://drive.google.com/file/d/1tgcyzWE9eaj5PbvVx9XQDsgv_gP7HD-m/view" }
        ],
        icon: <img src={bcmImg} width="100%" alt="beChangeMaker" />
    },
    {
        companyName: " ACM-VIT Student Chapter",
        role: "Technical Director",
        startDate: "04/2019",
        endDate: "03/2020",
        info: [
            "ACM-VIT: One of the most reputed and distinguished technical chapters in VIT.",
            "Worked on several projects as a core member.",
            "Managed various projects, events and workshops as the Technical Director.",
        ],
        links: [
            { title: "About Chapter", link: "http://acmvit.in/" },
            { title: "Certificate", link: "https://drive.google.com/file/d/1MwKvdW0JeS9i-QpG-f1GtU-i-p3onrLR/view?usp=sharing" }
        ],
        icon: <img src={acmImg} width="100%" alt="acm-vit" />
    }
]

export default experiences