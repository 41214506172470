import React, { useEffect } from 'react'
import skills from "../../dataModel/skills";
import '../../css/skills.css'
import { TagCloud } from 'react-tagcloud'



const data = skills.map((s, i) => ({ value: s, count: i }))

const customRenderer = (tag, size, color) => (
  <span
    key={tag.value}
    style={{
      animation: 'blinker 3s linear infinite',
      animationDelay: `${Math.random() * 2}s`,
      fontSize: `${size}px`,
      border: `2px solid ${color}`,
      margin: '3px',
      padding: '3px',
      display: 'inline-block',
      color,
    }}
  >
    {tag.value}
  </span>
)

const options = {
  luminosity: 'dark',
  hue: 'blue',
}


const SK = () => {
  useEffect(() => {
    document.title = `Skills | Shubham Awasthi`
  }, [])
  return (
    <div className="skills-wrapper animateWrapper">
      <TagCloud
        minSize={15}
        maxSize={40}
        colorOptions={options}
        style={{width: '80%'}} 
        renderer={customRenderer} 
        tags={data} /> ̰
    </div>
  )
}

export default SK