import React, { useEffect } from 'react'
import '../../css/coding-profile.css'
import cpContent from '../../dataModel/codingProfiles'
import { Box, Text, Button } from 'react-desktop/macOs';
const Platform = ({ info }) => {
  return (
    <Box padding="10px 10px" className="elem">
      <img src={info.image} height="100" alt={info.name} />
      <Text>
        <h2 style={{ fontSize: "1.5rem" }}>{info.name}</h2>
        <div style={{ fontSize: "1rem" }}>
          {info.rating ? <div><b>Rating</b>: {info.rating}</div> : '-'}
        </div>
        <div style={{ fontSize: "1rem" }}>
          {info.title ? `${info.title}` : '-'}
        </div>
        <div>
          <a className="no-decoration" href={info.link} target="_blank" rel="noreferrer"  >
            <Button color="blue">
              {info.username}
            </Button>
          </a>
        </div>
      </Text>

    </Box>
  )
}
const CP = () => {
  useEffect(() => {
    document.title = `Coding Profile | Shubham Awasthi`
  }, [])
  return (
    <div className="cp-wrapper animateWrapper">
      <div className="platform-wrapper">
        {
          (() => {
            return cpContent.platforms.map((el, ind) => <Platform key={ind} info={el} />)
          })()
        }
        <Box className="total-ques-wrapper">
          <h2>Problems Solved</h2>
          <table width="100%" >
            <thead>
              <tr>
                <th>Platform</th>
                <th>Number of Problems</th>
                <th>Sources</th>
              </tr>
            </thead>
            <tbody>
              {
                (() => {
                  var total = 0;
                  var arr = cpContent.numberOfProblems.map((el, ind) => {
                    total += el.count;
                    return (
                      <tr key={ind}>
                        <td>{el.platform}</td>
                        <td>{el.count}+</td>
                        <td>
                          <a className="no-decoration" href={el.source.link} target="_blank" rel="noreferrer"  >
                            <Button color="blue" >{el.source.title}</Button>
                          </a>
                        </td>
                      </tr>
                    )

                  })
                  arr.push(
                    <tr key={'total'}>
                      <th>Total</th>
                      <th>{total}+</th>
                      <th>&nbsp;</th>
                    </tr>
                  )
                  return arr;
                })()
              }

            </tbody>
          </table>
        </Box>
        <Box className="perf-wrapper">
          {/* <Text> */}
          <h2>Top Performances</h2>
          <table width="100%" >
            <thead>
              <tr>
                <th>Platform</th>
                <th>Contest</th>
                <th>
                  Rank/Total <br />
                  <span className="show-on-vsmall">(Percentile)</span>
                </th>
                <th className="hide-on-vsmall">Percentile</th>
              </tr>
            </thead>
            <tbody>
              {
                (() => {
                  return cpContent.topPerformsStats.map((el, ind) => {
                    const per = Math.round(el.rank * 10000 / el.total, 2) / 100;
                    return (
                      <tr key={ind}>
                        <td>{el.platform}</td>
                        <td>{el.contest}</td>
                        <td>{el.rank}/{el.total} <span className="show-on-vsmall"><br /><b>(p{per})</b> </span></td>
                        <td className="hide-on-vsmall">{per}</td>
                      </tr>
                    )
                  })
                })()
              }
            </tbody>

          </table>
        </Box>
      </div>

    </div>
  )
}

export default CP